<!-- <template>
  <LoginDialog @login-success="onLoginSuccess()"></LoginDialog>
  <div class="sub-header" :class="customClass">
    <div class="container">
      <nav class="navbar navbar-expand navbar-light">
        <div class="collapse navbar-collapse d-flex justify-content-between">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/horses-for-sale">Horses for Sale</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/service-providers">Service Providers</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/equipment-for-sale">Equipment</router-link>
            </li>
          </ul>
          <div class="logo">
            <router-link to="/"><img src="../assets/img/sub_logo.png" height="72" /></router-link>
          </div>
          <ul class="navbar-nav justify-content-end">
            <li class="nav-item" style="padding-left: 8px;">
              <router-link class="nav-link" to="/about">About</router-link>
            </li>
            <li class="nav-item" style="padding-left: 8px; padding-right: 8px;">
              <router-link class="nav-link" to="/faq">FAQ's/Tutorial Videos</router-link>
            </li>
            <li class="nav-item guest-link" v-if="$store.state.token === null" style="padding-right: 16px;">
              <a class="nav-link" href="javascript:;" @click="openRegisterModal()">Create Account</a>
            </li>
            <li class="nav-item guest-link" v-if="$store.state.token === null">
              <a class="nav-link action-button" href="javascript:;" @click="openLoginModal();">Sign In</a>
            </li>
            <li class="nav-item auth-link dropdown" v-if="$store.state.token !== null">
              <a class="nav-link dropdown-toggle" id="auth-name" href="#" role="button" data-toggle="dropdown" style="display: flex; align-items: center">
                {{$store.state.user.displayName}} <img src="../assets/img/icon_profile.png" height="18" style="margin-left: 4px;" />
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <router-link class="dropdown-item" to="/my-profile">Edit Profile</router-link>
                <router-link class="dropdown-item" to="/my-listings">
                  <div class="dropdown-item-container">
                    <span>Manage Horse Listings</span>
                    <span class="dropdown-item-small">(Edit Listing Details, Update Payment Method, Change Plan, Delete Listing)</span>
                  </div>
                </router-link>
                <router-link class="dropdown-item" to="/my-services">
                  <div class="dropdown-item-container">
                    <span>Manage Services Listings</span>
                    <span class="dropdown-item-small">(Edit Listing Details, Update Payment Method, Change Plan, Delete Listing)</span>
                  </div>
                </router-link>
                <router-link class="dropdown-item" to="/my-equipment">
                  <div class="dropdown-item-container">
                    <span>Manage Equipment Listings</span>
                    <span class="dropdown-item-small">(Edit Listing Details, Update Payment Method, Change Plan, Delete Listing)</span>
                  </div>
                </router-link>
                <router-link class="dropdown-item" to="/my-saved-horses">My Saved Searches - Horses</router-link>
                <router-link class="dropdown-item" to="/my-saved-services">My Saved Searches - Service Providers</router-link>
                <router-link class="dropdown-item" to="/my-saved-equipment">My Saved Searches - Equipment</router-link>
                <router-link class="dropdown-item" to="/my-ads">Manage Advertisements</router-link>
                <router-link class="dropdown-item" to="/my-saved-filters">My Filtered Searches</router-link>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript:;" @click="signOut();">Sign Out</a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <nav class="mobile-navbar navbar">
        <router-link class="navbar-brand" to="/"><img src="../assets/img/sub_logo.png" height="40" /></router-link>
        <button class="navbar-toggler" type="button" @click="show_mobile_menu = true">
          <BarsOutlined />
        </button>
        <div class="mobile-menu" v-if="show_mobile_menu">
          <a href="javascript:;" @click="show_mobile_menu = false"><CloseCircleFilled /></a>
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/horses-for-sale">Horses for Sale</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/service-providers">Service Providers</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/equipment-for-sale">Equipment for Sale</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/faq">Help</router-link>
            </li>
            <li class="nav-item guest-link" v-if="$store.state.token === null" style="padding-left: 30px; padding-right: 30px;">
              <a class="nav-link" href="javascript:;" @click="openRegisterModal()">Create Account</a>
            </li>
            <li class="nav-item guest-link" v-if="$store.state.token === null">
              <a class="nav-link action-button" href="javascript:;" @click="openLoginModal();">Sign In</a>
            </li>
            <li class="nav-item auth-link" v-if="$store.state.token !== null">
              <router-link class="nav-link" to="/my-profile">Edit Profile</router-link>
            </li>
            <li class="nav-item auth-link" v-if="$store.state.token !== null">
              <router-link class="nav-link" to="/my-listings">
                <div class="dropdown-item-container">
                  <span>Manage Horse Listings</span>
                  <span class="dropdown-item-small">(Edit Listing Details, Update Payment Method, Change Plan, Delete Listing)</span>
                </div>
              </router-link>
            </li>
            <li class="nav-item auth-link" v-if="$store.state.token !== null">
              <router-link class="nav-link" to="/my-services">
                <div class="dropdown-item-container">
                  <span>Manage Services Listings</span>
                  <span class="dropdown-item-small">(Edit Listing Details, Update Payment Method, Change Plan, Delete Listing)</span>
                </div>
              </router-link>
            </li>
            <li class="nav-item auth-link" v-if="$store.state.token !== null">
              <router-link class="nav-link" to="/my-equipment">
                <div class="dropdown-item-container">
                  <span>Manage Equipment Listings</span>
                  <span class="dropdown-item-small">(Edit Listing Details, Update Payment Method, Change Plan, Delete Listing)</span>
                </div>
              </router-link>
            </li>
            <li class="nav-item auth-link" v-if="$store.state.token !== null">
              <router-link class="nav-link" to="/my-saved-horses">My Saved Searches - Horses</router-link>
            </li>
            <li class="nav-item auth-link" v-if="$store.state.token !== null">
              <router-link class="nav-link" to="/my-saved-services">My Saved Searches - Service Providers</router-link>
            </li>
            <li class="nav-item auth-link" v-if="$store.state.token !== null">
              <router-link class="nav-link" to="/my-saved-equipment">My Saved Searches - Equipment</router-link>
            </li>
            <li class="nav-item auth-link" v-if="$store.state.token !== null">
              <router-link class="nav-link" to="/my-ads">Manage Advertisements</router-link>
            </li>
            <li class="nav-item auth-link" v-if="$store.state.token !== null">
              <router-link class="nav-link" to="/my-saved-filters">My Filtered Searches</router-link>
            </li>
            <li class="nav-item auth-link" v-if="$store.state.token !== null">
              <a class="nav-link" href="javascript:;" @click="signOut();">Sign Out</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template> -->
<template>
  <div class="sub-header">
    <MainHeader></MainHeader>
  </div>
</template>

<script>
import $ from 'jquery';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/dropdown';
import {auth} from '@/firebase';
import LoginDialog from "@/components/LoginDialog.vue";
import {BarsOutlined, CloseCircleFilled} from '@ant-design/icons-vue';
import MainHeader from '@/components/MainHeader.vue';

export default {
  components: {LoginDialog, BarsOutlined, CloseCircleFilled, MainHeader},
  props: ['customClass'],
  data() {
    return {
      show_mobile_menu: false
    }
  },
  methods: {
    async signOut() {
      try {
        await auth.signOut();
      } catch (error) {
        console.log(error);
      }
      this.$router.replace('/');
    },
    openLoginModal() {
      $('#loginModal').modal('show');
      $('#myTab a[href="#sign-in-content"]').tab('show')
    },
    openRegisterModal() {
      $('#loginModal').modal('show');
      $('#myTab a[href="#create-account-content"]').tab('show')
    },
    onLoginSuccess() {
      this.$emit('login-success');
    }
  }
}
</script>

<style lang="scss" scoped>
  .mobile-menu {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 10000;
    padding: 20px;

    ul {
      width: 100%;
    }
  }

  .navbar {
    padding: 0.5rem 0;
    @media only screen and (max-width: 960px) {
      display: none;
      padding: 0px 0px;
    }
  }

  .navbar-brand {
    @media only screen and (max-width: 960px) {
      flex-grow: 1;
    }
  }

  .mobile-navbar {
    display: none;
    @media only screen and (max-width: 960px) {
      display: flex;
      justify-content: flex-start;
    }
  }

  .nav-link {
    white-space: nowrap;
  }

  .navbar-nav > .nav-item:first-child > a {
    padding-left: 0;
  }

  .navbar-nav.justify-content-end > .nav-item:last-child > a {
    padding-right: 0;
  }

  .dropdown-item-container {
    display: flex;
    flex-direction: column;
  }

  .dropdown-item-small {
    font-size: 12px;
    white-space: wrap;
  }
</style>